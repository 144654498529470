import { Translations } from '../types/translation';
import { enTerms } from './en-terms';

export const EnLanguage: Record<Translations, string> = {
    login: 'Log in',
    logout: 'Log out',
    profile: 'Profile',
    makeOrder: 'Make an order',
    myOrders: 'My orders',
    delete: 'Delete',
    name: 'Name',
    phone: 'Number',
    surname: 'Surname',
    transactions: 'Transactions',
    uploadPhoto: 'Upload a photo',
    userSettings: 'User Settings',
    email: 'Email',
    save: 'Save',
    transactionsEmpty: 'The transaction list is empty',
    addressSettings: 'Setting up the delivery address',
    apartmentNumber: 'Apartment number',
    city: 'City/Town',
    country: 'Country',
    district: 'District',
    entrance: 'Entrance',
    houseNumber: 'House number',
    region: 'Region',
    street: 'Street',
    cancel: 'Cancel',
    userDeleteContent: 'After deletion, the account will become unavailable',
    userDeleteTitle: 'Do you really want to delete the account?',
    applicationCompleted: 'Application is completed',
    deliveryDate: 'Delivery data',
    direction: 'Direction',
    fromWhom: 'From',
    paid: 'Paid',
    sendTime: 'Sending time',
    shippingCompany: 'Transfer company',
    status: 'Status',
    toWhom: 'To',
    trackingNumber: 'Tracking number',
    editOrder: 'Edit order',
    payOrder: 'Pay order',
    payOrderTitle: 'Pay order №',
    currency: 'Currency',
    sum: 'Amount',
    pay: 'Pay',
    orderFrom: 'From',
    orderPackageCost: 'Price',
    orderPackageHeight: 'Height',
    orderPackageItemsCount: 'Number of items',
    orderPackageLength: 'Length',
    orderPackageCode: 'Product code',
    orderPackageName: 'Package contents',
    orderPackageRuName: 'Package contents (Russian)',
    orderPackageWeight: 'Weight',
    orderPackageWidth: 'Width',
    orderPackageInformation: 'Parcel info',
    orderTo: 'To',
    back: 'Back',
    next: 'Next',
    update: 'Update',
    checkout: 'Make an order',
    orderConfirmation: 'Confirmation',
    orderFiller: 'Filler',
    orderFromTo: 'From-To',
    orderRecipient: 'Receiver',
    orderSender: 'Sender',
    orderTransportation: 'Transportation',
    orderDeliveryToCompanyTypeTitle: 'Option of transfer to a Transport Company',
    orderFillerEmail: 'Filler email',
    orderPayer: 'Payer',
    orderMemberRecipientEmail: 'Email',
    orderMemberRecipientFullAddress: 'Full address',
    orderMemberRecipientName: 'Name',
    orderMemberRecipientPhone: 'Phone number',
    orderMemberRecipientPostalCode: 'Postal code',
    orderMemberRecipientSurname: 'Surname',
    orderMemberRecipientMiddlename: 'Middle name',
    orderMemberRecipientCity: 'City',
    orderMemberRecipientStreet: 'Street',
    orderMemberRecipientHouse: 'House number',
    orderMemberRecipientBuilding: 'Building number',
    orderMemberRecipientApartment: 'Number of the apartment/apartments',
    orderMemberRecipientRegion: 'Region',
    orderMemberRecipientRepresentative: "Name of the beneficiary's representative",
    orderMemberSenderEmail: 'Sender email',
    orderMemberSenderFullAddress: 'Full sender address',
    orderMemberSenderName: 'Sender Name',
    orderMemberSenderPhone: 'Sender phone number',
    orderMemberSenderPostalCode: 'Sender postal code',
    orderMemberSenderSurname: 'Sender surname',
    orderMemberSenderMiddlename: 'Sender middle name',
    orderMemberSenderCity: "Sender's city",
    orderMemberSenderStreet: "Sender's Street",
    orderMemberSenderHouse: "Sender's house number",
    orderMemberSenderBuilding: "Sender's building number",
    orderMemberSenderApartment: "Sender's apartment number",
    orderMemberSenderRegion: "Sender's region",
    orderMemberPassport: 'Passport',
    orderMemberPassportDate: 'Date of Issue',
    orderMemberPassportDivisionCode: 'Division Code',
    orderMemberPassportIssuingAuthority: 'Issuing Authority',
    orderMemberPassportNumber: 'Passport ID Number',
    orderMemberPassportSerialNumber: 'Passport ID Serial Number',
    orderCancel: 'Cancel',
    orderConfirm: 'Order confirmation',
    userInfoUpdatedMessage: 'The information successfully updated',
    userPhotoUpdatedMessage: 'The photo successfully uploaded',
    no: 'No',
    yes: 'Yes',
    authorization: 'Authorization',
    authorizationByEmail: 'by email',
    authorizationByPhone: 'by phone number',
    authorizationType: 'Authorization type',
    passwordForgot: 'Forget password?',
    registration: 'Registration',
    password: 'Password',
    field: 'Field',
    isMandatory: 'is mandatory',
    enteredIncorrectly: 'wrong value',
    confirmPassword: 'Password confirmation',
    register: 'Register',
    passwordsDoNotMatch: 'The entered passwords do not match',
    registrationRejected: 'Registration is canceled',
    registrationRejectedMessage: 'You have not accepted the terms of use of the service Telega',
    serviceName: 'Telega',
    termsOfService: 'The terms of use of the service',
    passwordRestore: 'Password Recovery',
    passwordRestoreMessage: 'The password reset code has been sent to the email',
    passwordRestoredMessage: 'The password has been successfully reset',
    restore: 'Recover',
    accept: 'Accept',
    welcome: 'Welcome',
    goToOrdersList: 'Go to the list of orders',
    orderPlacedSuccessfully: 'The order has been placed successfully!',
    yourOrderNumber: 'Order number:',
    httpRequestUnexpectedError: 'Unexpected error',
    httpRequestServeError: 'Data loading error',
    httpRequestUserNotAuthorized: 'The user is not logged in',
    httpRequestUserNotFound: 'The requested object was not found',
    termsContent: enTerms,
    orderCreatedDate: 'Checkout date',
    emailNotConfirmed: 'Email Not Confirmed',
    confirm: 'Confirm',
    confirmationCode: 'Confirmation Code',
    getQrCode: 'Get a QR code',
    amountToPay: 'Amount to be paid',
    paySucceed: 'Payment was successful',
    deliveryTime: 'Time of delivery',
    deliveryType: 'Type of delivery',
    orderQrInfo: 'Please scan this code with your smartphone or tablet and the label will be saved to your device. Pack your parcel and take it to the nearest GLS office in your city. Present this label on the spot, the department employee will print it out and stick it on your parcel.',
    inputOfLatinLetters: 'Only english language characters are allowed',
    amountToPayChanged: 'Order price has changed',
    incorrectPhoneNumber: 'Phone number entered incorrectly',
    paymentTypeSelection: 'Choose the type of payment system',
    maxLength: 'Maximum length',
    maxGirth: 'Maximum girth',
    maxWeight: 'Maximum weight',
    sanctionedGoodsInfo: 'Kindly ask you to read the list of goods prohibited for import into the Russian Federation',
    deleteConfirmation: 'Do you really want to delete the selected entry?',
    confirmAction: 'Confirm the action',
    orderPackageItemDuty: 'Shipments worth more than {amount} euros are subject to a duty of {percent}% in terms of exceeding the declared value of the threshold for duty-free import into the Russian Federation',
    orderMemberFullNameInfo: 'In case your first/last name is written in Cyrillic, please use transliteration',
    orderMemberCityInfo: 'Please enter the name of the city in English',
    orderMemberStreetInfo: 'Please transliterate the street name',
    orderMemberRegionInfo: 'Please enter the name of the region in English',
    orderPackageItemUrl: 'Link to the product',
    orderPackagePickupDate: 'Pickup date',
    expressShipment: 'Express-Shipment',
    regularShipment: 'Regular Shipment',
    parcelInsurance: 'Parcel insurance',
    myPurchases: 'My purchases',
    shopName: 'Name of Shop',
    warehouse: 'Warehouse',
    goodsList: 'List of goods',
    goods: 'Goods',
    myAddresses: 'My addresses',
    address: 'Address',
    companyName: 'Parcel recipient',
    postalCode: 'Postal code',
    workMode: 'Working mode',
    fullName: 'Full name',
    orderType: 'Type of order',
    perItem: 'per item',
    parcelDimensionsAttentionInformation: 'Please enter the dimensions and weight of the parcel correctly! In case of discrepancy between the declared and actual data, the cost of the service will be adjusted.',
    promoCode: 'Promo code',
    apply: 'Apply',
    discount: 'Discount',
    paidAmount: 'Paid amount',
    goodName: 'Productname',
    representativePlaceholder: 'Fedorov Fedor Fedorovich',
    acceptTermsOfService: 'I accept the terms of the current offer',
    itemTotalCost: 'Total cost of this item',
    addPurchase: 'Add a purchase',
    addGood: 'Add product',
    purchaseAdding: 'Adding a purchase',
    productAdding: 'Adding a product',
    acceptPersonalData: 'I agree to the processing of personal data',
    acceptTermsOfUse: 'I accept the terms of use of the service',
    courierPickUpInfo: 'The courier will arrive from 8.00 to 18.00',
    inn: 'TIN',
    glsParcelShops: 'Find parcel shops',
    totalWeight: 'Total weight',
    warehouseAddresses: 'Warehouse addresses',
    parcels: 'Parcels',
    orders: 'Orders',
    parcelType: 'Parcel type',
    fillOrderPackages: 'Specify the contents of the parcel',
    autoFill: 'Autofill',
    sendingParcelToRussia: 'Sending a parcel to Russia',
    shoppingFromStores: 'Shopping from stores',
    china: 'China',
    europe: 'Europe',
    howToMakeOrder: 'How to make the order?',
    faq: 'FAQ',
    calculator: 'Calculator',
    support: 'Support',
    openMenu: 'Open menu',
    selectPurchasesError: 'Select your purchase to ship',
    pleaseDownloadLabel: 'Please do not forget to print label',
    addFirstPurchase: 'Add a purchase №1',
    addMorePurchase: 'Add another purchase',
    orderWithWarehouseInfo: 'For orders from marketplaces, we kindly ask you to indicate our company as the recipient of the order, and the delivery address of our corresponding warehouse.',
};
