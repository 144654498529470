import { Translations } from '../types/translation';
import { deTerms } from './de-terms';

export const DeLanguage: Record<Translations, string> = {
    login: 'Einloggen',
    logout: 'Ausloggen',
    profile: 'Mein Profil',
    makeOrder: 'Paketversand',
    myOrders: 'Meine Bestellungen',
    delete: 'Löschen',
    name: 'Vorname',
    phone: 'Telefonnummer',
    surname: 'Nachname',
    transactions: 'Transaktionen',
    uploadPhoto: 'Photo hochladen',
    userSettings: 'Benutzereinstellungen',
    email: 'E-Mail',
    save: 'Speichern',
    transactionsEmpty: 'Transaktionenliste ist leer',
    addressSettings: 'Einstellungen der Adresse',
    apartmentNumber: 'Wohnungsnummer',
    city: 'Stadt',
    country: 'Land',
    district: 'Bezirk',
    entrance: 'Hauseingang',
    houseNumber: 'Hausnummer',
    region: 'Gebiet',
    street: 'Straße',
    cancel: 'Abbrechen',
    userDeleteContent: 'Nach dem Löschen, wird das Benutzerkonto unzugänglich',
    userDeleteTitle: 'Möchten Sie Ihr Benutzerkonto wirklich löschen?',
    applicationCompleted: 'Anmeldung abgeschlossen',
    deliveryDate: 'Zeitfenster der voraussichtlichen Lieferung',
    direction: 'Richtung',
    fromWhom: 'Von wem',
    paid: 'Bezahlt',
    sendTime: 'Zeit der Abgabe',
    shippingCompany: 'Beförderndes Unternehmen',
    status: 'Status',
    toWhom: 'An wen',
    trackingNumber: 'Verfolgungsnummer',
    editOrder: 'Bestellung redaktieren',
    payOrder: 'Bestellung bezahlen',
    payOrderTitle: 'Bezahlung von Bestellungsnummer …',
    currency: 'Währung',
    sum: 'Summe',
    pay: 'Bezahlen',
    orderFrom: 'Woher',
    orderPackageCost: 'Geldwert',
    orderPackageHeight: 'Höhe',
    orderPackageItemsCount: 'Anzahl an Gegenstände',
    orderPackageLength: 'Länge',
    orderPackageCode: 'Produktcode',
    orderPackageName: 'Inhalt des Paketes',
    orderPackageRuName: 'Inhalt des Paketes (Russisch)',
    orderPackageWeight: 'Gewicht',
    orderPackageWidth: 'Breite',
    orderPackageInformation: 'Informationen zur Sendung',
    orderTo: 'Wohin',
    back: 'Zurück',
    next: 'Weiter',
    update: 'Aktualisieren',
    checkout: 'Checkout',
    orderConfirmation: 'Bestätigung',
    orderFiller: 'Besteller',
    orderFromTo: 'Woher-Wohin',
    orderRecipient: 'Empfänger',
    orderSender: 'Versender',
    orderTransportation: 'Transport',
    orderDeliveryToCompanyTypeTitle: 'Abgabeoptionen',
    orderFillerEmail: 'E-Mail des Bestellers',
    orderPayer: 'Zahler',
    orderMemberRecipientEmail: 'E-Mail',
    orderMemberRecipientFullAddress: 'Volle Adresse',
    orderMemberRecipientName: 'Vorname',
    orderMemberRecipientPhone: 'Mobilrufnummer',
    orderMemberRecipientPostalCode: 'Postleitzahl',
    orderMemberRecipientSurname: 'Nachname',
    orderMemberRecipientMiddlename: 'Vatersname',
    orderMemberSenderEmail: 'E-Mail',
    orderMemberSenderFullAddress: 'Volle Adresse',
    orderMemberRecipientCity: 'Empfängerstadt',
    orderMemberRecipientStreet: 'Die Straße',
    orderMemberRecipientHouse: 'Hausnummer',
    orderMemberRecipientBuilding: 'Die Baunummer',
    orderMemberRecipientApartment: 'Die Nummer der Wohnung/Wohnung',
    orderMemberRecipientRegion: 'Empfängerregion',
    orderMemberRecipientRepresentative: 'Der Name des Vertreters des Begünstigten',
    orderMemberSenderName: 'Vorname',
    orderMemberSenderPhone: 'Mobilrufnummer',
    orderMemberSenderPostalCode: 'Postleitzahl',
    orderMemberSenderSurname: 'Nachname',
    orderMemberSenderMiddlename: 'Vatersname',
    orderMemberSenderCity: 'Die Stadt des Absenders',
    orderMemberSenderStreet: 'Absenderstraße',
    orderMemberSenderHouse: 'Die Hausnummer des Absenders',
    orderMemberSenderBuilding: 'Nummer des Absendergebäudes',
    orderMemberSenderApartment: 'Die Nummer der Wohnung des Absenders',
    orderMemberSenderRegion: 'Region des Absenders',
    orderMemberPassport: 'Passport',
    orderMemberPassportDate: 'Datum der Ausstellung',
    orderMemberPassportDivisionCode: 'Code der zuständigen Behörde',
    orderMemberPassportIssuingAuthority: 'Ausgestellt von',
    orderMemberPassportNumber: 'Passport Nummer',
    orderMemberPassportSerialNumber: 'Passport Seriennummer',
    orderCancel: 'Abbrechen',
    orderConfirm: 'Bestellung bestätigen',
    userInfoUpdatedMessage: 'Benutzerinformationen erfolgreich aktualisiert',
    userPhotoUpdatedMessage: 'Benutzerfoto erfolgreich aktualisiert',
    no: 'Nein',
    yes: 'Ja',
    authorization: 'Anmeldung',
    authorizationByEmail: 'mit E-Mail',
    authorizationByPhone: 'mit Telefon',
    authorizationType: 'Art der Anmeldung',
    passwordForgot: 'Password vergessen',
    registration: 'Registrierung',
    password: 'Passwort',
    field: 'Feld',
    isMandatory: 'ist notwendig',
    enteredIncorrectly: 'falsch eingegeben',
    confirmPassword: 'Passwortbestätigung',
    register: 'Sich registrieren',
    passwordsDoNotMatch: 'Die eingegebenen Passwörter stimmen nicht überein ',
    registrationRejected: 'Anmeldung abgelehnt',
    registrationRejectedMessage: 'Sie haben den Nutzungsbedingungen nicht zugestimmt',
    serviceName: 'Telega',
    termsOfService: 'Nutzungsbedingungen von Dienstleistungen',
    passwordRestore: 'Passwort wiederherstellen',
    passwordRestoreMessage: 'Der Code zum Zurücksetzen des Passworts wird an die E-Mail gesendet',
    passwordRestoredMessage: 'Das Passwort wurde erfolgreich zurückgesetzt',
    restore: 'Wiederherstellen',
    accept: 'Annehmen',
    welcome: 'Herzlich Willkommen',
    goToOrdersList: 'Zu der Bestellungsliste',
    orderPlacedSuccessfully: 'Bestellung erfolgreich gemacht',
    yourOrderNumber: 'Ihre Bestellungsnummer',
    httpRequestUnexpectedError: 'Unvorhersehbarer Fehler',
    httpRequestServeError: 'Fehler beim Laden der Daten',
    httpRequestUserNotAuthorized: 'Benutzer ist nicht angemeldet',
    httpRequestUserNotFound: 'Suchanfrage konnte nicht gefunden werden',
    termsContent: deTerms,
    orderCreatedDate: 'Datum der Bestellung',
    emailNotConfirmed: 'Die E-Mail wurde nicht bestätigt',
    confirm: 'Bestätigen',
    confirmationCode: 'Bestätigungscode',
    getQrCode: 'QR-Code erhalten',
    amountToPay: 'Zu zahlender Betrag',
    paySucceed: 'Die Zahlung war erfolgreich',
    deliveryTime: 'Lieferzeit',
    deliveryType: 'Versandarten',
    orderQrInfo: 'Bitte scannen Sie diesen Code mit Ihrem Smartphone oder Tablet und das Label wird auf Ihrem Gerät gespeichert. Packen Sie Ihr Paket und bringen Sie es zum nächstgelegenen GLS-PaketShop in Ihrer Stadt. Legen Sie diesen Aufkleber vor Ort vor, der Abteilungsmitarbeiter druckt ihn aus und klebt ihn auf Ihr Paket.',
    inputOfLatinLetters: 'Es sind nur englischen Zeichen erlaubt',
    amountToPayChanged: 'Der Bestellpreis hat sich geändert',
    incorrectPhoneNumber: 'Telefonnummer falsch eingegeben',
    paymentTypeSelection: 'Wählen Sie die Art des Zahlungssystems',
    maxLength: 'Maximale Länge',
    maxGirth: 'Maximaler Umfang',
    maxWeight: 'Maximales Gewicht',
    sanctionedGoodsInfo: 'Wir bitten Sie ausdrücklich, die Liste der für die Einfuhr in die Russische Föderation verbotenen Güte zu lesen',
    deleteConfirmation: 'Möchten Sie den ausgewählten Datensatz wirklich löschen?',
    confirmAction: 'Aktion bestätigen',
    orderPackageItemDuty: 'Sendungen im Wert von über {amount} Euro unterliegen einer Zollgebühr von {percent}%, wenn der angegebene Wert der Schwelle für die zollfreie Einfuhr in die Russische Föderation überschritten wird',
    orderMemberFullNameInfo: 'Wenn Ihr Vor- /Nachname in kyrillischer Schrift geschrieben ist, verwenden Sie bitte die Transliteration',
    orderMemberCityInfo: 'Bitte geben Sie den Namen der Stadt auf Englisch ein',
    orderMemberStreetInfo: 'Bitte schreiben Sie den Straßennamen in Transliteration',
    orderMemberRegionInfo: 'Bitte geben Sie den Namen der Region auf Englisch ein',
    orderPackageItemUrl: 'Link zum Produkt',
    orderPackagePickupDate: 'Abholdatum',
    expressShipment: 'Express-Zustellung',
    regularShipment: 'Regelmäßige Zustellung',
    parcelInsurance: 'Paketversicherung',
    myPurchases: 'Meine Einkäufe',
    shopName: 'Name des Shops',
    warehouse: 'Aktie',
    goodsList: 'Produktliste',
    goods: 'Produkt',
    myAddresses: 'Meine Adressen',
    address: 'Adresse',
    companyName: 'Empfänger des Pakets',
    postalCode: 'Postleitzahl',
    workMode: 'Arbeitsmodus',
    fullName: 'Name Vorname',
    orderType: 'Art der Bestellung',
    perItem: 'pro Stück',
    parcelDimensionsAttentionInformation: 'Bitte geben Sie Maße und Gewicht korrekt ein! Im Falle einer Abweichung zwischen den angegebenen und den tatsächlichen Daten werden die Kosten der Dienstleistung angepasst.',
    promoCode: 'Aktionscode',
    apply: 'Anwenden',
    discount: 'Rabatt',
    paidAmount: 'Bezahlte Menge',
    goodName: 'Produktname',
    representativePlaceholder: 'Fedorov Fedor Fedorovich',
    acceptTermsOfService: 'Ich akzeptiere die Bedingungen der aktuellen Offerte',
    itemTotalCost: 'Gesamtpreis dieses Artikels',
    addPurchase: 'Fügen Sie einen Kauf hinzu',
    addGood: 'Produkt hinzufügen',
    purchaseAdding: 'Einen Kauf hinzufügen',
    productAdding: 'Ein Produkt hinzufügen',
    acceptPersonalData: 'Ich stimme der Verarbeitung personenbezogener Daten zu',
    acceptTermsOfUse: 'Ich akzeptiere die Nutzungsbedingungen des Dienstes',
    courierPickUpInfo: 'Die Abholzeiten sind von 08-18 Uhr',
    inn: 'Steuernummer',
    glsParcelShops: 'Paketshops finden',
    totalWeight: 'Gesamtgewicht',
    warehouseAddresses: 'Lageradressen',
    parcels: 'Flurstücke',
    orders: 'Bestellungen',
    parcelType: 'Flurstücksart',
    fillOrderPackages: 'Geben Sie den Inhalt des Pakets an',
    autoFill: 'Automatisch ausfüllen',
    sendingParcelToRussia: 'Ein Paket nach Russland versenden',
    shoppingFromStores: 'Einkaufen in Geschäften',
    china: 'China',
    europe: 'Europa',
    howToMakeOrder: 'Wie kann ich eine Bestellung aufgeben?',
    faq: 'Häufig gestellte Fragen',
    calculator: 'Kalkulator',
    support: 'Unterstützung',
    openMenu: 'Menü öffnen',
    selectPurchasesError: 'Wählen Sie Ihren zu versendenden Einkauf aus',
    pleaseDownloadLabel: 'Vergessen Sie nicht, Label auszudrucken',
    addFirstPurchase: 'Einen Kauf hinzufügen Nr. 1',
    addMorePurchase: 'Einen weiteren Einkauf hinzufügen',
    orderWithWarehouseInfo: 'Bei Bestellungen aus Marktplätzen bitten wir Sie, unser Unternehmen als Empfänger der Bestellung anzugeben, die Lieferadresse ist die Adresse unseres entsprechenden Lagers.',
};
