import React, {
    forwardRef, useState, useImperativeHandle, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'antd/lib/table/interface';
import { Button, Grid, Space } from 'antd';
import { DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import produce from 'immer';
import Table from '../table';
import { usePurchases } from '../../hooks/usePurchases';
import { useOrder } from '../../hooks/useOrder';
import { useFormWatchMode } from '../../hooks/useFormWatchMode';
import PurchaseFormModal, { IPurchaseFormModalRef } from '../purchase-form-modal';
import { IPurchase } from '../../types/purchase';
import PurchaseItemFormModal, { IPurchaseItemFormModalInstance } from '../purchase-item-form-modal';
import PurchaseItemsTable, { IPurchaseItemsTableRef } from '../purchase-items-table';
import EditIcon from '../../assets/icons/edit';
import TrashIcon from '../../assets/icons/trash';

export interface IOrderPurchasesRef {
    selectedRowKeys: Key[];
    setSelectedRowKeys: (value: Key[]) => void;
}

interface IOrderPurchasesProps {
    onRowSelect?: (selectedRows: Key[]) => void;
    warehouseId?: number;
}

function OrderPurchases({ onRowSelect, warehouseId }: IOrderPurchasesProps, ref: any) {
    const order = useOrder();
    const {
        data, deleteRow, loading, getList,
    } = usePurchases({
        canFetch: typeof warehouseId === 'number',
        inOrderForm: true,
        orderId: order.data?.id,
        warehouseId,
    });
    const { t } = useTranslation();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const { watchMode } = useFormWatchMode();
    const { md } = Grid.useBreakpoint();
    const formModal = useRef<IPurchaseFormModalRef>();
    const [visibleItems, setVisibleItems] = useState<number[]>([]);
    const itemFormModal = useRef<IPurchaseItemFormModalInstance>();
    const itemTables = useRef<Record<number, IPurchaseItemsTableRef>>({});
    const targetRow = useRef<IPurchase>();

    useImperativeHandle(ref, () => ({
        selectedRowKeys,
        setSelectedRowKeys,
    }));

    const onAddItemClick = (row: IPurchase) => {
        itemFormModal.current?.open(row);
        targetRow.current = row;
    };

    const onItemsShowClick = (id: number) => {
        setVisibleItems(produce(visibleItems, (draft) => {
            const index = draft.findIndex((el) => el === id);
            if (index > -1) {
                draft.splice(index, 1);
                delete itemTables.current[id];
            } else {
                draft.push(id);
            }
            return draft;
        }));
    };

    const onItemsTableRef = (purchase: IPurchase, tableRef: IPurchaseItemsTableRef) => {
        itemTables.current[purchase.id] = tableRef;
    };

    const onItemFormHide = () => {
        if (targetRow.current instanceof Object) {
            itemTables.current[targetRow.current.id]?.update();
            getList();
        }
    };

    const onRowSelectHandler = (keys: Key[]) => {
        if (typeof onRowSelect === 'function') {
            onRowSelect(keys);
        }
        setSelectedRowKeys(keys);
    };

    const expandedRowRender = (record: IPurchase) => (
        <Space className="w-100" direction="vertical" size="large">
            <Space className="w-100" direction={md ? 'horizontal' : 'vertical'} style={{ justifyContent: 'space-between' }} size="large">
                <Space size="middle">
                    <span>{`${t('status')}:`}</span>
                    <span className="order-status-span">{record.status}</span>
                </Space>
                <Space className="w-100" style={{ justifyContent: 'space-between' }}>
                    <Button
                        hidden={watchMode}
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() => onAddItemClick(record)}
                    >
                        {t('addGood')}
                    </Button>
                    <Space className="table-actions-column" direction="vertical" size="small" style={{ justifyContent: 'space-between' }}>
                        <Button
                            icon={visibleItems.includes(record.id) ? <UpOutlined /> : <DownOutlined />}
                            type="link"
                            onClick={() => onItemsShowClick(record.id)}
                        />
                        <Button
                            hidden={watchMode}
                            icon={<EditIcon />}
                            type="link"
                            onClick={() => formModal.current?.show(record.id)}
                        />
                        <Button
                            hidden={watchMode}
                            icon={<TrashIcon />}
                            type="link"
                            onClick={() => deleteRow(record.id)}
                        />
                    </Space>
                </Space>
            </Space>
            {visibleItems.includes(record.id) && (
                <PurchaseItemsTable
                    ref={(refTable: any) => onItemsTableRef(record, refTable)}
                    purchase={record}
                    onFormHide={getList}
                />
            )}
        </Space>
    );

    return (
        <>
            <Space className="w-100" direction="vertical" size="large">
                {typeof warehouseId === 'number' && !watchMode && (
                    <Button
                        onClick={() => formModal.current?.show('create')}
                        icon={<PlusOutlined />}
                        type="primary"
                        style={md ? undefined : { height: 48, width: 48, padding: 0 }}
                    >
                        {md && <span>{data && data.total > 0 ? t('addMorePurchase') : t('addFirstPurchase')}</span>}
                    </Button>
                )}
                <Table
                    scroll={{ x: true }}
                    rowKey="id"
                    loading={loading}
                    dataSource={data?.list}
                    pagination={watchMode ? false : {
                        current: data?.currentPage,
                        total: data?.total,
                        pageSize: data?.perPage,
                    }}
                    columns={[{
                        align: 'right',
                        dataIndex: 'id',
                        title: '№',
                    }, {
                        dataIndex: 'name',
                        title: t('goodName'),
                    }, {
                        dataIndex: 'shop_name',
                        title: t('shopName'),
                    }, {
                        dataIndex: 'warehouse',
                        title: t('warehouse'),
                    }, {
                        dataIndex: 'tracking_code',
                        title: t('trackingNumber'),
                    }, {
                        dataIndex: 'total_weight',
                        title: t('totalWeight'),
                    }, {
                        dataIndex: 'sum',
                        title: t('sum'),
                    }]}
                    rowSelection={watchMode ? undefined : {
                        selectedRowKeys,
                        onChange: onRowSelectHandler,
                        renderCell(value, record, index, originNode) {
                            // @ts-ignore
                            return React.cloneElement(originNode, { disabled: record.items_count === 0 });
                        },
                    }}
                    expandable={{
                        expandedRowKeys: data?.list.map((el) => el.id),
                        expandedRowRender,
                    }}
                />
            </Space>
            <PurchaseFormModal ref={formModal} onSaved={getList} warehouseId={warehouseId} />
            <PurchaseItemFormModal ref={itemFormModal} onHide={onItemFormHide} />
        </>
    );
}

export default forwardRef(OrderPurchases);
