import React, { PropsWithChildren, useMemo, useState } from 'react';
import { IOrderPackageUnits } from '../types/order';
import { ISettings } from '../types/settings';

interface IAppContext {
    packageUnits?: IOrderPackageUnits;
    setPackageUnits: (val: IOrderPackageUnits) => void;
    settings?: ISettings;
    setSettings: (val: ISettings) => void;
    drawerOpen: boolean;
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = React.createContext<IAppContext>({
    drawerOpen: false,
    setDrawerOpen: () => {},
    setPackageUnits: () => {},
    setSettings: () => {},
});

function AppContextWrapper({ children }: PropsWithChildren) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [packageUnits, setPackageUnits] = useState<IOrderPackageUnits>();
    const [settings, setSettings] = useState<ISettings>();

    const value = useMemo(() => ({
        drawerOpen,
        setDrawerOpen,
        packageUnits,
        setPackageUnits,
        settings,
        setSettings,
    }), [drawerOpen, packageUnits]);

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
}

export default AppContextWrapper;
