import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import {
    Drawer, Grid, Layout,
} from 'antd';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../components/header';
import Menu from '../components/menu';

import '../styles/authed-layout.less';
import { AppContext } from '../context/app';

const { useBreakpoint } = Grid;

const {
    Content, Sider,
} = Layout;

function AuthedLayout({ children }: React.PropsWithChildren) {
    const { lg } = useBreakpoint();
    const appContext = useContext(AppContext);
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const { i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const drawerOpen = searchParams.get('drawerOpen') === 'true';
    const drawerInitilized = useRef(false);

    const onDrawerClose = () => appContext.setDrawerOpen(false);

    const onMenuClick = () => appContext.setDrawerOpen((prev) => !prev);

    useEffect(() => {
        if (lg) {
            appContext.setDrawerOpen(false);
        }
    }, [lg]);

    useEffect(() => {
        if (!drawerOpen) {
            onDrawerClose();
        }
    }, [drawerOpen, location.pathname]);

    useEffect(() => {
        if (drawerInitilized.current) {
            if (appContext.drawerOpen && !drawerOpen) {
                searchParams.append('drawerOpen', 'true');
            }
            if (!appContext.drawerOpen && drawerOpen) {
                searchParams.delete('drawerOpen');
            }
            setSearchParams(searchParams);
        } else {
            drawerInitilized.current = true;
            appContext.setDrawerOpen(drawerOpen);
        }
    }, [appContext.drawerOpen]);

    return (
        <Layout>
            <Sider
                hidden={!lg}
                breakpoint="lg"
                collapsible
                collapsedWidth={!lg ? 0 : undefined}
                theme="light"
                onCollapse={setCollapsed}
                width={{
                    'ru-RU': 300,
                    'en-EN': 305,
                    'de-DE': 310,
                }[i18n.language]}
            >
                <Menu collapsed={collapsed} />
            </Sider>
            <Drawer
                className="mobile-menu-drawer"
                placement="top"
                open={!lg && drawerOpen}
                onClose={onDrawerClose}
                width={250}
                bodyStyle={{ padding: 0 }}
                closable={false}
            >
                <Menu drawer />
            </Drawer>
            <Layout>
                <Header drawerOpen={drawerOpen} showMenuBtn={!lg} onMenuClick={onMenuClick} />
                <Content className="authed-layout-content">
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}

export default AuthedLayout;
